'use client';

import { useEffect } from 'react';

import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import createLegacyRouter from 'legacyRouter';
import { usePathname, useSearchParams } from 'next/navigation';
import createStore from 'store';

import { fetchAuthIfNeeded } from 'dux/auth/actions';
import { setUserCountry } from 'dux/user/actions';
import { locationChanged } from 'dux/router/slice';

const store = createStore();

const persistor = persistStore(store);

type LayoutProps = {
  children: React.ReactNode;
  userGeolocationGuessedCountry: string;
};

const StoreProvider = ({ children, userGeolocationGuessedCountry }: LayoutProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  /**
   * Code to be uncommented once the first Next.js is being implemented
   *
   * import { useRouter } from 'next/router';
   * const router = useRouter();
   * useEffect(() => {
   *   const handleRouteChange = url => {
   *     // sync redux store
   *     store.dispatch(
   *       locationChanged({
   *         location: {
   *           pathname: url,
   *           search: '',
   *         },
   *       })
   *     );
   *     // sync legacy router
   *     const oldRouter = createLegacyRouter();
   *     oldRouter.navigate(url);
   *   };
   *   router.events.on('routeChangeStart', handleRouteChange);
   *   // If the component is unmounted, unsubscribe
   *   // from the event with the `off` method:
   *   return () => {
   *     router.events.off('routeChangeStart', handleRouteChange);
   *   };
   * }, [router, store]);
   */

  useEffect(() => {
    store.dispatch(
      locationChanged({
        location: {
          pathname,
          search: searchParams.toString(),
        },
      }),
    );
    createLegacyRouter();
    // @Marc: you can now use the current URL
  }, []);

  useEffect(() => {
    /**
     * @Marc: ensure to be fetching on the first state that gets rendered
     * This will trigger feature flag fetch via middleware
     */
    store.dispatch(fetchAuthIfNeeded());
  }, []);

  useEffect(() => {
    // Storing the user guessed country to make it accessible to all pages
    store.dispatch(setUserCountry(userGeolocationGuessedCountry));
  }, [userGeolocationGuessedCountry]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default StoreProvider;
